<template>
  <Toast></Toast>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '700px' }"
    header="CAMBIAR M&Eacute;TODO DE PAGO"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <label for=""><strong>VENTA #: </strong>{{ data_pago.id }}</label>
        </div>
        <div class="field col-12 md:col-3">
          <label for=""
            ><strong>T.VENTA: </strong>
            {{ convertirNumeroGermanicFormat(data_pago.total_cancelar) }}
            Bs.</label
          >
        </div>
        <div class="field col-12 md:col-2">
          <label for=""><strong>NIT: </strong>{{ data_pago.nit }}</label>
        </div>
        <div class="field col-12 md:col-5">
          <label for=""
            ><strong>RAZ&Oacute;N SOCIAL: </strong
            >{{ data_pago.razon_social }}</label
          >
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="metodo_pago"
            ><strong>M&Eacute;TODO DE PAGO:</strong></label
          >
          <Dropdown
            id="metodo_pago"
            v-model="data_pago.metodo_pago_id"
            :options="metodo_pagos"
            optionLabel="nombre"
            class="w-full"
            optionValue="id"
          />
        </div>
        <div class="field col-12 md:col-4" v-if="data_pago.metodo_pago_id != 1">
          <label for=""><strong>MONTO M&Eacute;TODO PAGO: </strong></label>
          <InputNumber
            v-model="data_pago.transferencias"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :max="data_pago.total_cancelar - data_pago.efectivo"
          />
        </div>
        <div class="field col-12 md:col-4" v-if="data_pago.metodo_pago_id == 2">
          <label for=""><strong>N&Uacute;MERO TARJETA: </strong></label>
          <InputNumber
            v-model="nro_tarjeta"
            :min="8"
            :inputClass="'text-right'"
            locale="de-ED"
            placeholder="Ingrese el número de tarjeta"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label for=""><strong>MONTO EFECTIVO: </strong></label>
          <InputNumber
            v-model="data_pago.efectivo"
            :minFractionDigits="2"
            mode="decimal"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :max="data_pago.total_cancelar - data_pago.transferencias"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          >Campos Requeridos: <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger p-button-lg"
        @click="closeModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="ACTUALIZAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="updateMetodoPago"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import MetodoPagoService from "@/service/MetodoPagoService.js";
import VentaService from "@/service/VentaService.js";

export default {
  name: "VentaMetodoPago",
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    metodoPago: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.show,
      data_pago: this.metodoPago,
      enviado: false,
      errors: {},
      metodo_pagos: [],
      efectivo: 0,
      transferencias: 0,
      nro_tarjeta: 0,
    };
  },
  metodoPagoService: null,
  ventaService: null,
  created() {
    this.metodoPagoService = new MetodoPagoService();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.getMetodoPagos();
  },
  methods: {
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    updateMetodoPago() {
      if (this.data_pago.metodo_pago_id == 2) {
        if (
          this.nro_tarjeta == 0 ||
          this.nro_tarjeta.toString().length < 8
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Numero de tarjeta",
            detail: "El numero de tarjeta debe tener al menos 8 digitos",
            life: 60000,
          });
          this.guardando = false;
          return;
        }
      }
      this.enviado = true;
      // Preparo los datos para enviar
      let datos = {
        id: this.data_pago.id,
        metodo_pago_id: this.data_pago.metodo_pago_id,
        efectivo: this.data_pago.efectivo,
        transferencias: this.data_pago.transferencias,
        nro_tarjeta: this.nro_tarjeta || 0,
      };

      this.ventaService
        .updateMetodosPago(datos)
        .then((response) => {
          this.enviado = false;
          this.$toast.add({
            severity: "success",
            summary: "Actualizado",
            detail: response.mensaje,
            life: 3000,
          });
          this.$emit("actualizarListado");
          this.closeModal();
        })
        .catch((error) => {
          this.enviado = false;
          this.errors = error.response.data.errors;
        });
    },
    closeModal() {
      this.$emit("closeModal");
      this.enviado = false;
      this.errors = {};
      this.data_pago = {};
      this.nro_tarjeta = 0;
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    metodoPago(val) {
      this.data_pago = val;
    },
    //SI MI METODO DE PAGO ES EFECTIVO, NO DEBE PERMITIR INGRESAR MONTO EN TRANSFERENCIAS
    "data_pago.metodo_pago_id"(val) {
      if (val != 1) {
        this.data_pago.transferencias = parseFloat(
          this.data_pago.total_cancelar
        );
        this.data_pago.efectivo = parseFloat(0);
      } else {
        this.data_pago.efectivo = parseFloat(this.data_pago.total_cancelar);
        this.data_pago.transferencias = parseFloat(0);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
